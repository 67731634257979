import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { getAccessToken, getRefreshToken, renewAccessToken } from "./jwt";
import { UseMutationOptions, UseQueryOptions } from "@tanstack/react-query";
import qs from 'qs';
import { Dayjs } from "dayjs";

/**
 * ----------------------------------------------------------------
 * This file contains all the api endpoints to DRF and other places
 * ----------------------------------------------------------------
 */

// ---------------------- PAGE APIS ----------------------
export const LOGOUT_PAGE_API = "/api/frontend/logout/";
export const LOGGED_IN_BASE_PAGE_API = "/api/frontend/logged-in-base/";
export const SIGNUP_PLAN_SELECTION_API = "/api/frontend/signup-plan-selection/";
export const DASHBOARD_PAGE_API = "/api/frontend/dashboard/";
export const PROFILE_PAGE_API = "/api/frontend/profile/";
export const CONTACT_US_PAGE_API = "/api/frontend/contact-us/";
export const CONNECT_WEBSITE_PAGE_API = "/api/frontend/connect-website/";
export const MAX_WEBSITES_PAGE_API = "/api/frontend/max-websites/";
export const CONTENT_PLAN_PAGE_API = "/api/frontend/content-plan/";
export const ARTICLES_PAGE_API = "/api/frontend/articles/";
export const KEYWORDS_PAGE_API = "/api/frontend/keywords/";
export const KEYWORD_DETAILS_PAGE_API = "/api/frontend/keyword-details/";
export const ARTICLE_EDIT_PAGE_API = "/api/frontend/articles/edit/";
export const SETTINGS_PAGE_API = "/api/frontend/settings/";
export const PLANS_PAGE_API = "/api/frontend/plans/";
export const WP_SUCCESS_PAGE_API = "/api/frontend/wordpress-integration-success/";
export const WEBFLOW_SUCCESS_PAGE_API = "/api/frontend/webflow-integration-success/";
export const GOOGLE_SUCCESS_PAGE_API = "/api/frontend/google-integrations-success/";
export const ARCHIVE_ARTICLES_PAGE_API = "/api/frontend/archive-articles/";
export const ARCHIVE_HOW_TO_ARTICLES_PAGE_API = "/api/frontend/archive-how-to-articles/";
export const INDEXATION_PAGE_API = "/api/frontend/indexation";
export const KEYWORD_RESEARCH_PAGE_API = "/api/frontend/get-keyword-projects";
export const CONTENT_AUTOMATION_PAGE_API = "/api/frontend/get-automation-projects";
export const GOOGLE_LOGIN_AND_SIGNUP_PAGE_API = "/api/frontend/google-login-signup/success"
export const KEYWORD_PROJECT_PAGE_API = "/api/frontend/keyword-project";
export const KEYWORD_PROJECT_KEYWORD_TITLES_PAGE_API = "/api/frontend/keyword-project/keyword-titles";


// ------------------------- API Related Functions -------------------------

export class APIError extends Error {
	statusCode: number
	responseData: { [key: string]: any }

	constructor(statusCode: number, responseData: { [key: string]: any }) {
		super(`API request failed with status ${statusCode}`);
		this.statusCode = statusCode;
		this.responseData = responseData;
		this.name = "APIError";
	}
}

// TODO: Remove this later once all usages have been replaced with Tanstack Query
/**
 * Authenticates and fetches data from provided url path. Auto-renews access token in case it was expired.
 *
 * Only use this to make individual api requests. For page data in react routing, use getLoggedInPageData() and
 * getLoggedOutPageData() functions.
 *
 * @param path - api path.
 * @param requestType - 'get' or 'post' request.
 * @param data - optional data to pass in request.
 */
export async function makeApiRequest(path: string,
	requestType: "get" | "post",
	data?: object): Promise<AxiosResponse> {

	let accessToken = getAccessToken();
	if (!accessToken) throw Error("access token missing");

	let axiosConfig: AxiosRequestConfig = {
		method: requestType,
		url: process.env.REACT_APP_DRF_DOMAIN + path,
		responseType: 'json',
		headers: {
			'Authorization': 'Bearer ' + accessToken
		}
	}
	if (data) {
		if (requestType === 'get') {
			axiosConfig.params = data;
		} else {
			axiosConfig.data = data;
		}
	}

	try {
		// success
		return await axios(axiosConfig);
	} catch (err) {
		const axiosError = err as AxiosError;
		// If error is 401, renew the access token using refresh token and then try again.
		// Otherwise log / forward the error.
		if (axiosError.response?.status === 401) {
			let refreshToken: string | null = getRefreshToken();
			if (refreshToken) {
				const renewalSuccessful: boolean = await renewAccessToken(refreshToken);
				if (renewalSuccessful) {
					accessToken = getAccessToken();
					axiosConfig.headers = {
						'Authorization': 'Bearer ' + accessToken
					}
					try {
						// success
						return await axios(axiosConfig);
					} catch (err) {
						// all other status codes
						const axiosError = err as AxiosError
						throw new APIError(axiosError.response!.status, axiosError.response!.data as object);
					}
				} else {
					// TODO: redirect to login page??? Not sure :/
					throw new Error("token renewal failed");
				}
			} else {
				throw new Error("refresh token missing");
			}
		} else {
			// all other status codes
			throw new APIError(axiosError.response!.status, axiosError.response!.data as object);
		}
	}
}

// -------------------------------------------------------------------------------------------------------------------

const axiosInstance = axios.create()

function axiosInterceptorSuccessFn(response: AxiosResponse) {
	return response;
}

async function axiosInterceptorFailFn(error: any) {
	const axiosError = error as AxiosError;
	if (axiosError.response?.status === 401) {
		// Renew access token
		let refreshToken: string | null = getRefreshToken();
		if (refreshToken) {
			// Success
			const renewalSuccessful: boolean = await renewAccessToken(refreshToken);
			if (renewalSuccessful) {
				// return Promise.reject('access_token_renewed');
				return Promise.reject(error);
			} else {
				return Promise.reject("Access token renewal failed. Refresh token is most likely stale.");
			}
			// missing refresh token
		} else {
			return Promise.reject("Refresh token missing");
		}
		// some other error
	} else if (error.response) {
		return Promise.reject(error.response.data);
	} else if (error.request) {
		// The request was made but no response was received
		return Promise.reject({ message: 'No response received from server' });
	} else if (error.message) {
		// Something happened in setting up the request that triggered an Error
		return Promise.reject({ message: error.message });
	} else {
		return Promise.reject(`Request failed with status code ${axiosError.response?.status}`);
	}
}

export function retryFn(failureCount: number, error: any) {
	const axiosError = error as AxiosError;
	return axiosError.response?.status === 401;
}

function makeAuthGetRequest(apiPath: string) {
	let accessToken = getAccessToken();
	if (!accessToken) throw Error("access token missing");

	let axiosConfig: AxiosRequestConfig = {
		method: 'get',
		url: process.env.REACT_APP_DRF_DOMAIN + apiPath,
		headers: {
			'Authorization': 'Bearer ' + accessToken
		},
	}
	axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
	return axiosInstance(axiosConfig);
}

function makeGetRequest(apiPath: string, queryData?: object) {
	let axiosConfig: AxiosRequestConfig = {
		method: 'get',
		url: process.env.REACT_APP_DRF_DOMAIN + apiPath,
		headers: {
			'Content-Type': 'application/json'
		},
		data: qs.stringify(queryData)
	}
	axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
	return axiosInstance(axiosConfig);
}

function makeAuthPostRequest(apiPath: string, postData?: Object) {
	let accessToken = getAccessToken();
	if (!accessToken) throw Error("access token missing");

	let axiosConfig: AxiosRequestConfig = {
		method: 'post',
		url: process.env.REACT_APP_DRF_DOMAIN + apiPath,
		responseType: 'json',
		headers: {
			'Authorization': 'Bearer ' + accessToken
		},
		data: postData
	}

	axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
	return axiosInstance(axiosConfig);
}

function makeDownloadRequest(apiPath: string) {
	let accessToken = getAccessToken();
	if (!accessToken) throw Error("access token missing");

	let axiosConfig: AxiosRequestConfig = {
		method: 'get',
		responseType: "blob",
		url: process.env.REACT_APP_DRF_DOMAIN + apiPath,
		headers: {
			'Authorization': 'Bearer ' + accessToken
		}
	}
	axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
	return axiosInstance(axiosConfig);
}


// ========================================================================
// ---------------------- ACCOUNT EMAIL VERIFICATION ----------------------
// ========================================================================

export function accountEmailVerificationQuery(token: string | undefined): UseQueryOptions {
	return {
		queryKey: ['accountEmailVerificationAPI'],
		queryFn: () => {
			let axiosConfig: AxiosRequestConfig = {
				method: 'post',
				url: process.env.REACT_APP_DRF_DOMAIN + `/api/frontend/verify-email/`,
				responseType: 'json',
				data: {
					token: token
				}
			}
			axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
			return axiosInstance(axiosConfig);
		},
		refetchOnWindowFocus: false,
		retry: (failureCount, error) => {
			const axiosError = error as AxiosError;
			return axiosError.response?.status === 401;
		}
	}
}

// =================================================================
// ---------------------- FORGOT PASSWORD API ----------------------
// =================================================================

export const forgotPasswordMutation: UseMutationOptions<AxiosResponse<any, any>, any, string> = {
	mutationKey: ['forgotPasswordAPI'],
	mutationFn: (email: string) => {
		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/forgot-password/',
			responseType: 'json',
			data: {
				email: email,
			}
		}
		return axiosInstance(axiosConfig);
	},
}


// ================================================================
// ---------------------- RESET PASSWORD API ----------------------
// ================================================================

export const resetPasswordMutation: UseMutationOptions<AxiosResponse<any, any>, any, {
	encryptedEmail: string
	resetUID: string
	newPassword: string
}> = {
	mutationKey: ['resetPasswordAPI'],
	mutationFn: (postData: { encryptedEmail: string, resetUID: string, newPassword: string }) => {
		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/reset-password/',
			responseType: 'json',
			data: {
				encrypted_email: postData['encryptedEmail'],
				reset_password_uid: postData['resetUID'],
				new_password: postData['newPassword'],
			}
		}
		return axiosInstance(axiosConfig);
	},
}


// ==============================================================
// ---------------------- DOMAIN CHECK API ----------------------
// ==============================================================

export const domainCheckMutation: UseMutationOptions<AxiosResponse<any, any>, any, string> = {
	mutationKey: ['domainCheckAPI'],
	mutationFn: (domain: string) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'get',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/check-domain/?domain=' + domain,
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		const axiosError = error as AxiosError;
		return axiosError.response?.status === 401;
	}
}

// ===================================================================
// ---------------------- TITLE DESCRIPTION API ----------------------
// ===================================================================

export function titleDescQuery(protocol: string, domain: string, enableAPI: boolean): UseQueryOptions {
	return {
		queryKey: ['titleDescAPI'],
		queryFn: () => titleDescQueryFn(protocol, domain),
		refetchOnWindowFocus: false,
		retry: (failureCount, error) => {
			// Error message 'access_token_renewed' is returned by the interceptor code after renewing the access token.
			// In this case return true so that we can attempt the api call again.
			// return error === 'access_token_renewed';
			const axiosError = error as AxiosError;
			return axiosError.response?.status === 401;
		},
		enabled: enableAPI,
		cacheTime: 0
	}
}

function titleDescQueryFn(protocol: string, domain: string) {
	let accessToken = getAccessToken();
	if (!accessToken) throw Error("access token missing");

	let axiosConfig: AxiosRequestConfig = {
		method: 'get',
		url: process.env.REACT_APP_DRF_DOMAIN + `/api/frontend/get-title-description/?protocol=${protocol}&domain=${domain}`,
		responseType: 'json',
		headers: {
			'Authorization': 'Bearer ' + accessToken
		}
	}
	axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
	return axiosInstance(axiosConfig);
}

// ==============================================================
// ---------------------- ICP INDUSTRY API ----------------------
// ==============================================================

export function icpIndustryQuery(domain: string, title: string, description: string, enableAPI: boolean): UseQueryOptions {
	return {
		queryKey: ['icpIndustryAPI'],
		queryFn: () => icpIndustryQueryFn(domain, title, description),
		refetchOnWindowFocus: false,
		retry: (failureCount, error) => {
			// Error message 'access_token_renewed' is returned by the interceptor code after renewing the access token.
			// In this case return true so that we can attempt the api call again.
			// return error === 'access_token_renewed';
			const axiosError = error as AxiosError;
			return axiosError.response?.status === 401;
		},
		enabled: enableAPI,
		cacheTime: 0
	}
}

function icpIndustryQueryFn(domain: string, title: string, description: string) {
	let accessToken = getAccessToken();
	if (!accessToken) throw Error("access token missing");

	let axiosConfig: AxiosRequestConfig = {
		method: 'get',
		url: process.env.REACT_APP_DRF_DOMAIN + `/api/frontend/get-industry-icp-text/?&domain=${domain}&title=${title}&description=${description}`,
		responseType: 'json',
		headers: {
			'Authorization': 'Bearer ' + accessToken
		}
	}
	axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
	return axiosInstance(axiosConfig);
}

// =============================================================
// ---------------------- COMPETITORS API ----------------------
// =============================================================

export function competitorsQuery(protocol: string, domain: string): UseQueryOptions {
	return {
		queryKey: ['competitorsAPI'],
		queryFn: () => competitorsQueryFn(protocol, domain),
		refetchOnWindowFocus: false,
		retry: (failureCount, error) => {
			// Error message 'access_token_renewed' is returned by the interceptor code after renewing the access token.
			// In this case return true so that we can attempt the api call again.
			// return error === 'access_token_renewed';
			const axiosError = error as AxiosError;
			return axiosError.response?.status === 401;
		},
		enabled: false,
		cacheTime: 0,
	}
}

function competitorsQueryFn(protocol: string, domain: string) {
	let accessToken = getAccessToken();
	if (!accessToken) throw Error("access token missing");

	let axiosConfig: AxiosRequestConfig = {
		method: 'get',
		url: process.env.REACT_APP_DRF_DOMAIN + `/api/frontend/get-competitors/?protocol=${protocol}&domain=${domain}`,
		responseType: 'json',
		headers: {
			'Authorization': 'Bearer ' + accessToken
		}
	}
	axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
	return axiosInstance(axiosConfig);
}

// =================================================================
// ---------------------- CONNECT WEBSITE API ----------------------
// =================================================================

export function connectWebsiteQuery(data: Object): UseQueryOptions {
	return {
		queryKey: ['connectWebsiteAPI'],
		queryFn: () => connectWebsiteQueryFn(data),
		refetchOnWindowFocus: false,
		retry: (failureCount, error) => {
			// Error message 'access_token_renewed' is returned by the interceptor code after renewing the access token.
			// In this case return true so that we can attempt the api call again.
			// return error === 'access_token_renewed';
			const axiosError = error as AxiosError;
			return axiosError.response?.status === 401;
		},
		enabled: false
	}
}

function connectWebsiteQueryFn(data: Object) {
	let accessToken = getAccessToken();
	if (!accessToken) throw Error("access token missing");

	let axiosConfig: AxiosRequestConfig = {
		method: 'post',
		url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/connect-website/',
		responseType: 'json',
		headers: {
			'Authorization': 'Bearer ' + accessToken
		},
		data: data
	}
	axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
	return axiosInstance(axiosConfig);
}


// =====================================================================
// ---------------------- LOAD ARTICLE TITLES API ----------------------
// =====================================================================

export function loadArticleTitlesQuery(): UseQueryOptions {
	return {
		queryKey: ['loadArticleTitlesAPI'],
		queryFn: () => loadArticleTitlesQueryFn(),
		refetchOnWindowFocus: false,
		retry: (failureCount, error) => {
			// Error message 'access_token_renewed' is returned by the interceptor code after renewing the access token.
			// In this case return true so that we can attempt the api call again.
			// return error === 'access_token_renewed';
			const axiosError = error as AxiosError;
			return axiosError.response?.status === 401;
		}
	}
}

function loadArticleTitlesQueryFn() {
	let accessToken = getAccessToken();
	if (!accessToken) throw Error("access token missing");

	let axiosConfig: AxiosRequestConfig = {
		method: 'get',
		url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/get-article-titles/',
		responseType: 'json',
		headers: {
			'Authorization': 'Bearer ' + accessToken
		}
	}
	axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
	return axiosInstance(axiosConfig);
}


// =====================================================================
// ---------------------- LOAD HOW-TO ARTICLE TITLES API ----------------------
// =====================================================================

export function loadHowToArticleTitlesQuery(): UseQueryOptions {
	return {
		queryKey: ['loadHowToArticleTitlesAPI'],
		queryFn: () => loadHowToArticleTitlesQueryFn(),
		refetchOnWindowFocus: false,
		retry: (failureCount, error) => {
			// Error message 'access_token_renewed' is returned by the interceptor code after renewing the access token.
			// In this case return true so that we can attempt the api call again.
			// return error === 'access_token_renewed';
			const axiosError = error as AxiosError;
			return axiosError.response?.status === 401;
		}
	}
}

function loadHowToArticleTitlesQueryFn() {
	let accessToken = getAccessToken();
	if (!accessToken) throw Error("access token missing");

	let axiosConfig: AxiosRequestConfig = {
		method: 'get',
		url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/get-how-to-article-titles/',
		responseType: 'json',
		headers: {
			'Authorization': 'Bearer ' + accessToken
		}
	}
	axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
	return axiosInstance(axiosConfig);
}


// =====================================================================
// ---------------------- LOAD List Based Article ARTICLE TITLES API ----------------------
// =====================================================================

export function loadListicleTitlesQuery(): UseQueryOptions {
	return {
		queryKey: ['loadListicleTitlesAPI'],
		queryFn: () => loadListicleTitlesQueryFn(),
		refetchOnWindowFocus: false,
		retry: (failureCount, error) => {
			// Error message 'access_token_renewed' is returned by the interceptor code after renewing the access token.
			// In this case return true so that we can attempt the api call again.
			// return error === 'access_token_renewed';
			const axiosError = error as AxiosError;
			return axiosError.response?.status === 401;
		}
	}
}

function loadListicleTitlesQueryFn() {
	let accessToken = getAccessToken();
	if (!accessToken) throw Error("access token missing");

	let axiosConfig: AxiosRequestConfig = {
		method: 'get',
		url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/get-listicle-titles/',
		responseType: 'json',
		headers: {
			'Authorization': 'Bearer ' + accessToken
		}
	}
	axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
	return axiosInstance(axiosConfig);
}


// =====================================================================
// ------------------ LOAD ARCHIVE ARTICLE TITLES API ------------------
// =====================================================================

export function loadArchiveArticleTitlesQuery(): UseQueryOptions {
	return {
		queryKey: ['loadArchiveArticleTitlesAPI'],
		queryFn: () => loadArchiveArticleTitlesQueryFn(),
		refetchOnWindowFocus: false,
		retry: (failureCount, error) => {
			// Error message 'access_token_renewed' is returned by the interceptor code after renewing the access token.
			// In this case return true so that we can attempt the api call again.
			// return error === 'access_token_renewed';
			const axiosError = error as AxiosError;
			return axiosError.response?.status === 401;
		}
	}
}

function loadArchiveArticleTitlesQueryFn() {
	let accessToken = getAccessToken();
	if (!accessToken) throw Error("access token missing");

	let axiosConfig: AxiosRequestConfig = {
		method: 'get',
		url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/get-archive-article-titles/',
		responseType: 'json',
		headers: {
			'Authorization': 'Bearer ' + accessToken
		}
	}
	axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
	return axiosInstance(axiosConfig);
}

// =====================================================================
// ------------------ LOAD How-To ARCHIVE ARTICLE TITLES API ------------------
// =====================================================================

export function loadArchiveHowToArticleTitlesQuery(): UseQueryOptions {
	return {
		queryKey: ['loadArchiveArticleTitlesAPI'],
		queryFn: () => loadArchiveHowToArticleTitlesQueryFn(),
		refetchOnWindowFocus: false,
		retry: (failureCount, error) => {
			// Error message 'access_token_renewed' is returned by the interceptor code after renewing the access token.
			// In this case return true so that we can attempt the api call again.
			// return error === 'access_token_renewed';
			const axiosError = error as AxiosError;
			return axiosError.response?.status === 401;
		}
	}
}

function loadArchiveHowToArticleTitlesQueryFn() {
	let accessToken = getAccessToken();
	if (!accessToken) throw Error("access token missing");

	let axiosConfig: AxiosRequestConfig = {
		method: 'get',
		url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/get-archive-how-to-article-titles/',
		responseType: 'json',
		headers: {
			'Authorization': 'Bearer ' + accessToken
		}
	}
	axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
	return axiosInstance(axiosConfig);
}

// ==============================================================
// ---------------------- POST ARTICLE API ----------------------
// ==============================================================

export const postArticleMutation: UseMutationOptions<AxiosResponse<any, any>, any, {
	articleUID: string,
	selectedIntegration: string
	selectedIntegrationUniqueID: string
}> = {
	mutationKey: ['postArticleAPI'],
	mutationFn: (data: {
		articleUID: string,
		selectedIntegration: string,
		selectedIntegrationUniqueID: string
	}) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/post-article/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			},
			data: {
				article_uid: data.articleUID,
				selected_integration: data.selectedIntegration,
				selection_integration_id: data.selectedIntegrationUniqueID
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		const axiosError = error as AxiosError;
		return axiosError.response?.status === 401;
	}
}

// ==============================================================
// ------------------- BULK POST ARTICLE API --------------------
// ==============================================================

export const postBulkArticleMutation: UseMutationOptions<AxiosResponse<any, any>, any, {
	articlesUID: Array<string>,
	selectedIntegration: string
}> = {
	mutationKey: ['postArticleAPI'],
	mutationFn: (data: {
		articlesUID: Array<string>,
		selectedIntegration: string
	}) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/bulk-post-article/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			},
			data: {
				articles_uid: data.articlesUID,
				selected_integration: data.selectedIntegration
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		const axiosError = error as AxiosError;
		return axiosError.response?.status === 401;
	}
}

// ============================================================
// ---------------------- EDIT TITLE API ----------------------
// ============================================================

export const editTitleMutation: UseMutationOptions<AxiosResponse<any, any>, any, {
	articleUID: string,
	title: string
}> = {
	mutationKey: ['editTitleAPI'],
	mutationFn: (postData: { articleUID: string, title: string }) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/edit-article-title/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			},
			data: {
				article_uid: postData.articleUID,
				title: postData.title
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		const axiosError = error as AxiosError;
		return axiosError.response?.status === 401;
	}
}

// =========================================================================
// ---------------------- GENERATE ARTICLE TITLES API ----------------------
// =========================================================================

export const generateArticleTitleMutation: UseMutationOptions<AxiosResponse<any, any>, any, Array<{
	type_of_article: string,
	count: number
}>> = {
	mutationKey: ['generateArticleTitleAPI'],
	mutationFn: (Data: Array<{ type_of_article: string, count: number }>) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/generate-article-titles-api/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			},
			data: {
				type_of_article: Data[0].type_of_article,
				count: Data[0].count
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	},
	cacheTime: 0,
	retry: (failureCount: number, error: any) => {
		// Error message 'access_token_renewed' is returned by the interceptor code after renewing the access token.
		// In this case return true so that we can attempt the api call again.
		// return error === 'access_token_renewed';
		const axiosError = error as AxiosError;
		return axiosError.response?.status === 401;
	}
}

// ======================================================================
// ---------------------- GET WEBSITE KEYWORDS API ----------------------
// ======================================================================

export function getWebsiteSelectedKeywordsQuery(): UseQueryOptions {
	return {
		queryKey: ['getWebsiteKeywordsAPI'],
		queryFn: () => {
			let accessToken = getAccessToken();
			if (!accessToken) throw Error("access token missing");

			let axiosConfig: AxiosRequestConfig = {
				method: 'get',
				url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/get-website-selected-keywords/',
				responseType: 'json',
				headers: {
					'Authorization': 'Bearer ' + accessToken
				}
			}
			axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
			return axiosInstance(axiosConfig);
		},
		refetchOnWindowFocus: false,
		retry: (failureCount, error) => {
			// Error message 'access_token_renewed' is returned by the interceptor code after renewing the access token.
			// In this case return true so that we can attempt the api call again.
			// return error === 'access_token_renewed';
			const axiosError = error as AxiosError;
			return axiosError.response?.status === 401;
		}
	}
}

// ======================================================================
// ---------------------- GET List Based KEYWORDS API ----------------------
// ======================================================================

export function getListicleKeywordsQuery(): UseQueryOptions {
	return {
		queryKey: ['getWebsiteListicleKeywordsAPI'],
		queryFn: () => {
			let accessToken = getAccessToken();
			if (!accessToken) throw Error("access token missing");

			let axiosConfig: AxiosRequestConfig = {
				method: 'get',
				url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/get-listicles-keywords/',
				responseType: 'json',
				headers: {
					'Authorization': 'Bearer ' + accessToken
				}
			}
			axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
			return axiosInstance(axiosConfig);
		},
		refetchOnWindowFocus: false,
		retry: (failureCount, error) => {
			// Error message 'access_token_renewed' is returned by the interceptor code after renewing the access token.
			// In this case return true so that we can attempt the api call again.
			// return error === 'access_token_renewed';
			const axiosError = error as AxiosError;
			return axiosError.response?.status === 401;
		}
	}
}


// =======================================================================
// ---------------------- GET STRATEGY KEYWORDS API ----------------------
// =======================================================================

export function getStrategyKeywordsQuery(strategy: string): UseQueryOptions {
	return {
		queryKey: ['getStrategyKeywordsAPI'],
		queryFn: () => {
			let accessToken = getAccessToken();
			if (!accessToken) throw Error("access token missing");

			let axiosConfig: AxiosRequestConfig = {
				method: 'get',
				url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/get-strategy-keywords?strategy=' + strategy,
				responseType: 'json',
				headers: {
					'Authorization': 'Bearer ' + accessToken
				},
			}
			axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
			return axiosInstance(axiosConfig);
		},
		refetchOnWindowFocus: false,
		retry: (failureCount, error) => {
			// Error message 'access_token_renewed' is returned by the interceptor code after renewing the access token.
			// In this case return true so that we can attempt the api call again.
			// return error === 'access_token_renewed';
			const axiosError = error as AxiosError;
			return axiosError.response?.status === 401;
		}
	}
}


// ======================================================================
// ---------------------- ADD SELECTED KEYWORD API ----------------------
// ======================================================================

export const addSelectedKeywordMutation: UseMutationOptions<AxiosResponse<any, any>, any, string> = {
	mutationKey: ['addSelectedKeywordAPI'],
	mutationFn: (keywordHash: string) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/add-selected-keyword/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			},
			data: {
				keyword_hash: keywordHash
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		// Error message 'access_token_renewed' is returned by the interceptor code after renewing the access token.
		// In this case return true so that we can attempt the api call again.
		// return error === 'access_token_renewed';
		const axiosError = error as AxiosError;
		return axiosError.response?.status === 401;
	}
}


// ===============================================================================
// ---------------------- ADD MULTIPLE SELECTED KEYWORD API ----------------------
// ===============================================================================

export const addMultipleSelectedKeywordMutation: UseMutationOptions<AxiosResponse<any, any>, any, Array<string>> = {
	mutationKey: ['addMultipleSelectedKeywordAPI'],
	mutationFn: (keywordHashArray: Array<string>) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/add-multiple-selected-keyword/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			},
			data: {
				keyword_hash_list: keywordHashArray
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		// Error message 'access_token_renewed' is returned by the interceptor code after renewing the access token.
		// In this case return true so that we can attempt the api call again.
		// return error === 'access_token_renewed';
		const axiosError = error as AxiosError;
		return axiosError.response?.status === 401;
	}
}


// =================================================================
// ---------------------- UPLOAD KEYWORDS API ----------------------
// =================================================================

export const uploadKeywordsMutation: UseMutationOptions<AxiosResponse<any, any>, any, Array<string>> = {
	mutationKey: ['uploadKeywordsAPI'],
	mutationFn: (keywords: Array<string>) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/upload-keywords/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			},
			data: {
				keywords: keywords
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		// Error message 'access_token_renewed' is returned by the interceptor code after renewing the access token.
		// In this case return true so that we can attempt the api call again.
		// return error === 'access_token_renewed';
		const axiosError = error as AxiosError;
		return axiosError.response?.status === 401;
	}
}


// =================================================================
// ---------------------- UPLOAD KEYWORDS API ----------------------
// =================================================================

export const uploadListicleKeywordsMutation: UseMutationOptions<AxiosResponse<any, any>, any, Array<string>> = {
	mutationKey: ['uploadListicleKeywordsAPI'],
	mutationFn: (keywords: Array<string>) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/upload-listicles-keywords/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			},
			data: {
				ListicleKeywords: keywords
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		// Error message 'access_token_renewed' is returned by the interceptor code after renewing the access token.
		// In this case return true so that we can attempt the api call again.
		// return error === 'access_token_renewed';
		const axiosError = error as AxiosError;
		return axiosError.response?.status === 401;
	}
}

// =================================================================
// ------------------ UPLOAD KEYWORDS AND TITLES API ---------------
// =================================================================

export const uploadKeywordsAndTitlesMutation: UseMutationOptions<AxiosResponse<any, any>, any, Array<{ keyword: string, title: string }>> = {
	mutationKey: ['uploadKeywordsAndTitlesAPI'],
	mutationFn: (keywordsAndTitles: Array<{ keyword: string, title: string }>) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/upload-keywords-and-titles/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			},
			data: {
				keywords_and_titles: keywordsAndTitles
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		// Error message 'access_token_renewed' is returned by the interceptor code after renewing the access token.
		// In this case return true so that we can attempt the api call again.
		// return error === 'access_token_renewed';
		const axiosError = error as AxiosError;
		return axiosError.response?.status === 401;
	}
}

// =================================================================
// ------------------ UPLOAD KEYWORD AND LOCATION API ---------------
// =================================================================

export const uploadKeywordAndLocationMutation: UseMutationOptions<AxiosResponse<any, any>, any, {
	keyword: string,
	location_name: string,
	location_code: number
}> = {
	mutationKey: ['uploadKeywordAndLocationAPI'],
	mutationFn: (postData: { keyword: string, location_name: string, location_code: number }) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/upload-keyword-and-location/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			},
			data: {
				keyword: postData.keyword,
				location_name: postData.location_name,
				location_code: postData.location_code
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		// Error message 'access_token_renewed' is returned by the interceptor code after renewing the access token.
		// In this case return true so that we can attempt the api call again.
		// return error === 'access_token_renewed';
		const axiosError = error as AxiosError;
		return axiosError.response?.status === 401;
	}
}


// =================================================================
// ------------------ Generate Titles From Keyword API ---------------
// =================================================================

export const GenerateTitlesFromKeyword: UseMutationOptions<AxiosResponse<any, any>, any, {
	keyword_hash: string,
	location: string,
	count?: number
}> = {
	mutationKey: ['GenerateTitlesFromKeywordAPI'],
	mutationFn: (postData: { keyword_hash: string, location: string, count?: number }) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/generate-titles-from-keyword/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			},
			data: {
				keyword_hash: postData.keyword_hash,
				location: postData.location,
				count: postData.count ? postData.count : 10
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		// Error message 'access_token_renewed' is returned by the interceptor code after renewing the access token.
		// In this case return true so that we can attempt the api call again.
		// return error === 'access_token_renewed';
		const axiosError = error as AxiosError;
		return axiosError.response?.status === 401;
	}
}


export const GenerateTitlesFromGSCKeyword: UseMutationOptions<AxiosResponse<any, any>, any, {
	keyword: string,
	location: string,
	count?: number
}> = {
	mutationKey: ['GenerateTitlesFromKeywordAPI'],
	mutationFn: (postData: { keyword: string, location: string, count?: number }) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/generate-titles-from-gsc-keyword/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			},
			data: {
				keyword: postData.keyword,
				location: postData.location,
				count: postData.count ? postData.count : 10
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		// Error message 'access_token_renewed' is returned by the interceptor code after renewing the access token.
		// In this case return true so that we can attempt the api call again.
		// return error === 'access_token_renewed';
		const axiosError = error as AxiosError;
		return axiosError.response?.status === 401;
	}
}

// ================================================================
// ---------------------- REMOVE KEYWORD API ----------------------
// ================================================================

export const removeKeywordMutation: UseMutationOptions<AxiosResponse<any, any>, any, string> = {
	mutationKey: ['removeKeywordAPI'],
	mutationFn: (keyword: string) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/remove-keyword/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			},
			data: {
				keyword: keyword
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		// Error message 'access_token_renewed' is returned by the interceptor code after renewing the access token.
		// In this case return true so that we can attempt the api call again.
		// return error === 'access_token_renewed';
		const axiosError = error as AxiosError;
		return axiosError.response?.status === 401;
	}
}

// =========================================================================
// ---------------------- REMOVE SELECTED KEYWORD API ----------------------
// =========================================================================

export const removeSelectedKeywordMutation: UseMutationOptions<AxiosResponse<any, any>, any, string> = {
	mutationKey: ['removeSelectedKeywordAPI'],
	mutationFn: (keyword: string) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/remove-selected-keyword/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			},
			data: {
				keyword: keyword
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		// Error message 'access_token_renewed' is returned by the interceptor code after renewing the access token.
		// In this case return true so that we can attempt the api call again.
		// return error === 'access_token_renewed';
		const axiosError = error as AxiosError;
		return axiosError.response?.status === 401;
	}
}


// ======================================================================
// ---------------------- LOAD KEYWORD DETAILS API ----------------------
// ======================================================================

export function loadKeywordDetailsQuery(keywordHash: string): UseQueryOptions {
	return {
		queryKey: ['loadKeywordDetailsAPI'],
		queryFn: () => {
			let accessToken = getAccessToken();
			if (!accessToken) throw Error("access token missing");

			let axiosConfig: AxiosRequestConfig = {
				method: 'get',
				url: process.env.REACT_APP_DRF_DOMAIN + `/api/frontend/get-website-keyword-article-titles/?keywordHash=${keywordHash}`,
				responseType: 'json',
				headers: {
					'Authorization': 'Bearer ' + accessToken
				}
			}
			axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
			return axiosInstance(axiosConfig);
		},
		refetchOnWindowFocus: false,
		retry: (failureCount, error) => {
			// Error message 'access_token_renewed' is returned by the interceptor code after renewing the access token.
			// In this case return true so that we can attempt the api call again.
			// return error === 'access_token_renewed';
			const axiosError = error as AxiosError;
			return axiosError.response?.status === 401;
		}
	}
}

// =====================================================================================
// ---------------------- GENERATE KEYWORD DETAILS ARTICLE TITLES ----------------------
// =====================================================================================

export const keywordDetailsArticleTitleMutation: UseMutationOptions<AxiosResponse<any, any>, any, {
	keywordHash: string,
	count: number
}> = {
	mutationKey: ['keywordDetailsArticleTitleGenerationAPI'],
	mutationFn: (postData: { keywordHash: string, count: number }) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/generate-keyword-details-article-titles/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			},
			data: {
				keywordHash: postData['keywordHash'],
				count: postData['count'],
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		// Error message 'access_token_renewed' is returned by the interceptor code after renewing the access token.
		// In this case return true so that we can attempt the api call again.
		// return error === 'access_token_renewed';
		const axiosError = error as AxiosError;
		return axiosError.response?.status === 401;
	}
}


// ==============================================================
// ---------------------- GENERATE ARTICLE ----------------------
// ==============================================================

export const generateArticleMutation: UseMutationOptions<AxiosResponse<any, any>, any, string> = {
	mutationKey: ['articleGenerationAPI'],
	mutationFn: (articleUID: string) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/generate-article/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			},
			data: {
				article_uid: articleUID,
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		const axiosError = error as AxiosError;
		return axiosError.response?.status === 401;
	}
}


// ==============================================================
// -------------------- BULK GENERATE ARTICLE -------------------
// ==============================================================

export const generateBulkArticleMutation: UseMutationOptions<AxiosResponse<any, any>, any, Array<string>> = {
	mutationKey: ['bulkArticleGenerationAPI'],
	mutationFn: (articlesUID: Array<string>) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/bulk-generate-article/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			},
			data: {
				articles_uid: articlesUID,
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		const axiosError = error as AxiosError;
		return axiosError.response?.status === 401;
	}
}


// ==============================================================
// ---------------------- GENERATE V2 ARTICLE ----------------------
// ==============================================================

export const generateV2ArticleMutation: UseMutationOptions<AxiosResponse<any, any>, any, string> = {
	mutationKey: ['v2ArticleGenerationAPI'],
	mutationFn: (articleUID: string) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/generate-v2-article/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			},
			data: {
				article_uid: articleUID,
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		const axiosError = error as AxiosError;
		return axiosError.response?.status === 401;
	}
}


// ==============================================================
// -------------------- BULK GENERATE V2 ARTICLE -------------------
// ==============================================================

export const generateBulkV2ArticleMutation: UseMutationOptions<AxiosResponse<any, any>, any, Array<string>> = {
	mutationKey: ['bulkV2ArticleGenerationAPI'],
	mutationFn: (articlesUID: Array<string>) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/bulk-generate-v2-article/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			},
			data: {
				articles_uid: articlesUID,
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		const axiosError = error as AxiosError;
		return axiosError.response?.status === 401;
	}
}

// ==============================================================
// ---------------- BULK ARCHIVE/UNARCHIVE ARTICLE --------------
// ==============================================================

export const archiveBulkArticleMutation: UseMutationOptions<AxiosResponse<any, any>, any, {
	articlesUID: Array<string>,
	archiveType: "unarchive" | "archive"
}> = {
	mutationKey: ['bulkArticleGenerationAPI'],
	mutationFn: (postData: { articlesUID: Array<string>, archiveType: "unarchive" | "archive" }) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/bulk-archive-unarchive-articles/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			},
			data: {
				articles_uid: postData.articlesUID,
				archive_type: postData.archiveType,
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		const axiosError = error as AxiosError;
		return axiosError.response?.status === 401;
	}
}


// ======================================================================
// ---------------------- LOAD ARTICLE CONTENT API ----------------------
// ======================================================================

export function loadArticleContentQuery(articleUID: string): UseQueryOptions {
	return {
		queryKey: ['loadArticleContentAPI'],
		queryFn: () => {
			let accessToken = getAccessToken();
			if (!accessToken) throw Error("access token missing");

			let axiosConfig: AxiosRequestConfig = {
				method: 'get',
				url: process.env.REACT_APP_DRF_DOMAIN + `/api/frontend/get-article-content/?article_uid=${articleUID}`,
				responseType: 'json',
				headers: {
					'Authorization': 'Bearer ' + accessToken
				}
			}
			axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
			return axiosInstance(axiosConfig);
		},
		refetchOnWindowFocus: false,
		retry: (failureCount, error) => {
			const axiosError = error as AxiosError;
			return axiosError.response?.status === 401;
		},
		enabled: false,
		cacheTime: 0,
	}
}

// ===========================================================
// ---------------------- SAVE ARTICLE FEATURED IMAGE ----------------------
// ===========================================================

export const saveArticleFeaturedImgMutation: UseMutationOptions<AxiosResponse<any, any>, any, {
	template_id: string,
	article_uid: string,
	article_type: string
}> = {
	mutationKey: ['saveArticleFeaturedImgAPI'],
	mutationFn: (featuredImg: { template_id: string, article_uid: string, article_type: string }) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/update-article-feature-image/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			},
			data: {
				template_id: featuredImg.template_id,
				article_uid: featuredImg.article_uid,
				article_type: featuredImg.article_type
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		const axiosError = error as AxiosError;
		return axiosError.response?.status === 401;
	}
}

// ===============================================================
// ---------------------- ARTICLE GENERATED ----------------------
// ===============================================================

export function articleIsGeneratedQuery(articleUID: string): UseQueryOptions {
	return {
		queryKey: ['articleIsGeneratedAPI'],
		queryFn: () => {
			let accessToken = getAccessToken();
			if (!accessToken) throw Error("access token missing");

			let axiosConfig: AxiosRequestConfig = {
				method: 'get',
				url: process.env.REACT_APP_DRF_DOMAIN + `/api/frontend/article-is-generated/?article_uid=${articleUID}`,
				responseType: 'json',
				headers: {
					'Authorization': 'Bearer ' + accessToken
				}
			}
			axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
			return axiosInstance(axiosConfig);
		},
		refetchOnWindowFocus: false,
		refetchInterval: (data: any) => {
			if (data && data['data']['generated']) {
				return false
			} else {
				return 10000
			}
		},
		refetchIntervalInBackground: true,
		cacheTime: 0,
	}
}

// ==================================================================
// ---------------------- SAVE ARTICLE CONTENT ----------------------
// ==================================================================

export const saveArticleMutation: UseMutationOptions<AxiosResponse<any, any>, any, {
	article_uid: string,
	article_content: string,
	article_description: string,
	article_feedback?: string
}> = {
	mutationKey: ['saveArticleAPI'],
	mutationFn: (postData: { article_uid: string, article_content: string, article_description: string }) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/save-article/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			},
			data: {
				article_uid: postData['article_uid'],
				article_content: postData['article_content'],
				article_description: postData['article_description'],
				article_feedback: postData['article_feedback']
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		const axiosError = error as AxiosError;
		return axiosError.response?.status === 401;
	}
}

// ==============================================================
// ---------------------- POST ARTICLE IMAGE API ----------------------
// ==============================================================

export const saveArticleImageMutation: UseMutationOptions<AxiosResponse<any, any>, any, {
	article_uid: string,
	image: File,
	article_type: string,
	extension: string
}> = {
	mutationKey: ['saveArticleImageAPI'],
	mutationFn: (imageData: { article_uid: string, image: File, article_type: string, extension: string }) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		const formData = new FormData();
		formData.append("image", imageData.image);
		formData.append("article_uid", imageData.article_uid);
		formData.append("article_type", imageData.article_type);
		formData.append("extension", imageData.extension);

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/save-article-image/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			},
			data: formData
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		const axiosError = error as AxiosError;
		return axiosError.response?.status === 401;
	}
}

// ================================================================
// ---------------------- REMOVE INTEGRATION ----------------------
// ================================================================

export const removeIntegrationMutation: UseMutationOptions<AxiosResponse<any, any>, any, {
	integrationType: string,
	integrationUniqueID?: string
}> = {
	mutationKey: ['removeIntegrationAPI'],
	mutationFn: (data: {
		integrationType: string,
		integrationUniqueID?: string
	}) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/remove-all-integrations/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			},
			data: {
				integration_type: data.integrationType,
				integration_unique_id: data.integrationUniqueID ? data.integrationUniqueID : null
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		const axiosError = error as AxiosError;
		return axiosError.response?.status === 401;
	}
}

// ===========================================================
// ---------------------- SAVE SETTINGS ----------------------
// ===========================================================

export const saveSettingsMutation: UseMutationOptions<AxiosResponse<any, any>, any, {
	settingsToSave: Array<{ settingName: string, settingValue: any }>
}> = {
	mutationKey: ['saveSettingsAPI'],
	mutationFn: (postData: { settingsToSave: Array<{ settingName: string, settingValue: any }> }) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/save-website-settings/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			},
			data: {
				settings_to_save: postData['settingsToSave'],
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		const axiosError = error as AxiosError;
		return axiosError.response?.status === 401;
	}
}

export const enableOrEditAutoPublishMutation: UseMutationOptions<AxiosResponse<any, any>, any,
	{
		auto_publish_article_count: number, auto_publish_days: string,
		auto_publish_time: string, replace_current: boolean
	}> = {
	mutationKey: ['enableOrEditAutoPublishAPI'],
	mutationFn: (postData: {
		auto_publish_article_count: number, auto_publish_days: string,
		auto_publish_time: string, replace_current: boolean
	}) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/enable-or-edit-auto-publish/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			},
			data: {
				auto_publish_article_count: postData.auto_publish_article_count,
				auto_publish_days: postData.auto_publish_days,
				auto_publish_time: postData.auto_publish_time,
				replace_current: postData.replace_current,
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		const axiosError = error as AxiosError;
		return axiosError.response?.status === 401;
	}
}

export const editAutoPublishMutation: UseMutationOptions<AxiosResponse<any, any>, any,
	{ auto_publish_days: string, auto_publish_time: string, replace_current: boolean }> = {
	mutationKey: ['editAutoPublishAPI'],
	mutationFn: (postData: { auto_publish_days: string, auto_publish_time: string, replace_current: boolean }) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/edit-auto-publish/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			},
			data: {
				auto_publish_days: postData.auto_publish_days,
				auto_publish_time: postData.auto_publish_time,
				replace_current: postData.replace_current,
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		const axiosError = error as AxiosError;
		return axiosError.response?.status === 401;
	}
}

export const disableAutoPublishMutation: UseMutationOptions<AxiosResponse<any, any>, any> = {
	mutationKey: ['disableAutoPublishAPI'],
	mutationFn: () => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/disable-auto-publish/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		const axiosError = error as AxiosError;
		return axiosError.response?.status === 401;
	}
}

export const saveWebsiteDetailsMutation: UseMutationOptions<AxiosResponse<any, any>, any, {
	title: string,
	desc: string,
	ind: string,
	icp: string
}> = {
	mutationKey: ['saveWebsiteDetailsAPI'],
	mutationFn: (postData: { title: string, desc: string, ind: string, icp: string }) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/save-website-details/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			},
			data: {
				title: postData['title'],
				desc: postData['desc'],
				ind: postData['ind'],
				icp: postData['icp'],
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		const axiosError = error as AxiosError;
		return axiosError.response?.status === 401;
	}
}

export const saveUserDetailsMutation: UseMutationOptions<AxiosResponse<any, any>, any, {
	username: string,
	tz: string
}> = {
	mutationKey: ['saveUserDetailsAPI'],
	mutationFn: (postData: { username: string, tz: string }) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/save-user-details/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			},
			data: {
				username: postData['username'],
				tz: postData['tz'],
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		const axiosError = error as AxiosError;
		return axiosError.response?.status === 401;
	}
}

export const saveCompetitorsMutation: UseMutationOptions<AxiosResponse<any, any>, any, Array<{
	name: string,
	domain: string,
	logo: string
}>> = {
	mutationKey: ['saveCompetitorsAPI'],
	mutationFn: (postData: Array<{ name: string, domain: string, logo: string }>) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/edit-competitors/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			},
			data: {
				competitors: postData
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		const axiosError = error as AxiosError;
		return axiosError.response?.status === 401;
	}
}


// ========================================================================
// ---------------------- SUBSCRIPTION PLAN PURCHASE ----------------------
// ========================================================================

export const purchasePlanMutation: UseMutationOptions<AxiosResponse<any, any>, any,
	{ priceID: string, successURL: string, cancelURL: string }> = {
	mutationKey: ['purchasePlanAPI'],
	mutationFn: (postData: { priceID: string, successURL: string, cancelURL: string }) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/purchase-plan/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			},
			data: {
				price_id: postData['priceID'],
				success_url: postData['successURL'],
				cancel_url: postData['cancelURL']
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		const axiosError = error as AxiosError;
		return axiosError.response?.status === 401;
	}
}

// ===============================================================
// ---------------------- FREE PLAN PURCHASE ----------------------
// ===============================================================

export const useFreePlanMutation: UseMutationOptions<AxiosResponse<any, any>, any, string> = {
	mutationKey: ['useFreePlanAPI'],
	mutationFn: (priceID: string) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/use-free-plan/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			},
			data: {
				price_id: priceID,
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		const axiosError = error as AxiosError;
		return axiosError.response?.status === 401;
	}
}


// ===========================================================
// ---------------------- PLAN PURCHASE ----------------------
// ===========================================================

export const planChangeMutation: UseMutationOptions<AxiosResponse<any, any>, any, string> = {
	mutationKey: ['planChangeAPI'],
	mutationFn: (priceID: string) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/change-plan/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			},
			data: {
				price_id: priceID,
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		const axiosError = error as AxiosError;
		return axiosError.response?.status === 401;
	}
}


// ==============================================================
// ---------------------- CHECKOUT SUCCESS ----------------------
// ==============================================================

export function checkoutSuccessQuery(checkoutSessionID: string | null): UseQueryOptions {
	return {
		queryKey: ['checkoutSuccessAPI'],
		queryFn: () => {
			let accessToken = getAccessToken();
			if (!accessToken) throw Error("access token missing");

			let axiosConfig: AxiosRequestConfig = {
				method: 'get',
				url: process.env.REACT_APP_DRF_DOMAIN + `/api/frontend/checkout-success/?checkout_session_id=${checkoutSessionID}`,
				responseType: 'json',
				headers: {
					'Authorization': 'Bearer ' + accessToken
				}
			}
			axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
			return axiosInstance(axiosConfig);
		},
		refetchOnWindowFocus: false,
		retry: (failureCount, error) => {
			const axiosError = error as AxiosError;
			return axiosError.response?.status === 401;
		}
	}
}

// ===============================================================
// ---------------------- GET ALL PLAN DATA ----------------------
// ===============================================================

export function getAllPlanDataQuery(): UseQueryOptions {
	return {
		queryKey: ['getAllPlanDataAPI'],
		queryFn: () => {
			let accessToken = getAccessToken();
			if (!accessToken) throw Error("access token missing");

			let axiosConfig: AxiosRequestConfig = {
				method: 'get',
				url: process.env.REACT_APP_DRF_DOMAIN + `/api/frontend/get-all-plan-data/`,
				responseType: 'json',
				headers: {
					'Authorization': 'Bearer ' + accessToken
				}
			}
			axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
			return axiosInstance(axiosConfig);
		},
		refetchOnWindowFocus: false,
		retry: (failureCount, error) => {
			const axiosError = error as AxiosError;
			return axiosError.response?.status === 401;
		}
	}
}

// ======================================================================
// ---------------------- GET SUBSCRIPTION HISTORY ----------------------
// ======================================================================

export function getSubscriptionHistoryQuery(): UseQueryOptions {
	return {
		queryKey: ['getSubscriptionHistoryAPI'],
		queryFn: () => {
			let accessToken = getAccessToken();
			if (!accessToken) throw Error("access token missing");

			let axiosConfig: AxiosRequestConfig = {
				method: 'get',
				url: process.env.REACT_APP_DRF_DOMAIN + `/api/frontend/get-subscription-history/`,
				responseType: 'json',
				headers: {
					'Authorization': 'Bearer ' + accessToken
				}
			}
			axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
			return axiosInstance(axiosConfig);
		},
		refetchOnWindowFocus: false,
		retry: (failureCount, error) => {
			const axiosError = error as AxiosError;
			return axiosError.response?.status === 401;
		}
	}
}

// ====================================================================
// ---------------------- GET STRIPE PORTAL LINK ----------------------
// ====================================================================

export function stripePortalLinkQuery(): UseQueryOptions {
	return {
		queryKey: ['stripePortalLinkAPI'],
		queryFn: () => {
			let accessToken = getAccessToken();
			if (!accessToken) throw Error("access token missing");

			let axiosConfig: AxiosRequestConfig = {
				method: 'get',
				url: process.env.REACT_APP_DRF_DOMAIN + `/api/frontend/get-stripe-portal-link/`,
				responseType: 'json',
				headers: {
					'Authorization': 'Bearer ' + accessToken
				}
			}
			axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
			return axiosInstance(axiosConfig);
		},
		refetchOnWindowFocus: false,
		retry: (failureCount, error) => {
			const axiosError = error as AxiosError;
			return axiosError.response?.status === 401;
		}
	}
}

// =================================================================
// ---------------------- CARD PAYMENT FAILED ----------------------
// =================================================================

export const cardPaymentFailedMutation: UseMutationOptions<AxiosResponse<any, any>, any, string> = {
	mutationKey: ['cardPaymentFailedAPI'],
	mutationFn: (paymentIntentID: string) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/card-payment-failed/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			},
			data: {
				payment_intent_id: paymentIntentID,
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		const axiosError = error as AxiosError;
		return axiosError.response?.status === 401;
	}
}

// ===================================================================
// ---------------------- SWITCH ACTIVE WEBSITE ----------------------
// ===================================================================

export const switchWebsiteMutation: UseMutationOptions<AxiosResponse<any, any>, any, string> = {
	mutationKey: ['switchWebsiteAPI'],
	mutationFn: (newDomain: string) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/switch-active-website/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			},
			data: {
				domain: newDomain,
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		const axiosError = error as AxiosError;
		return axiosError.response?.status === 401;
	}
}


// =====================================================================
// ---------------------- SEND CONTACT US MESSAGE ----------------------
// =====================================================================

export const contactUsMutation: UseMutationOptions<AxiosResponse<any, any>, any,
	{ subject: string, messageBody: string, files: null | FileList }> = {
	mutationKey: ['contactUsAPI'],
	mutationFn: (postData: { subject: string, messageBody: string, files: null | FileList }) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let formData = new FormData();
		formData.append('subject', postData.subject);
		formData.append('message', postData.messageBody);
		if (postData.files) {
			Array.from(postData.files).forEach(file => {
				formData.append(file.name, file);
			});
		}

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/contact-us/send-message/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken,
				'Content-Type': 'multipart/form-data',
			},
			data: formData
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		const axiosError = error as AxiosError;
		return axiosError.response?.status === 401;
	}
}

// =============================================================================
// ---------------------- GET AUTOPOST ARTICLE TABLE DATA ----------------------
// =============================================================================

export function getAutopostArticleTableDataQuery(): UseQueryOptions {
	return {
		queryKey: ['getAutopostArticleTableDataAPI'],
		queryFn: () => {
			let accessToken = getAccessToken();
			if (!accessToken) throw Error("access token missing");

			let axiosConfig: AxiosRequestConfig = {
				method: 'get',
				url: process.env.REACT_APP_DRF_DOMAIN + `/api/frontend/autopost-article-table-data/`,
				responseType: 'json',
				headers: {
					'Authorization': 'Bearer ' + accessToken
				}
			}
			axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
			return axiosInstance(axiosConfig);
		},
		refetchOnWindowFocus: false,
		retry: (failureCount, error) => {
			const axiosError = error as AxiosError;
			return axiosError.response?.status === 401;
		}
	}
}

// =============================================================================
// ---------------------- GET AUTOPOST ARTICLE TABLE DATA ----------------------
// =============================================================================

export function getAutopostArticleScheduleTableDataQuery(): UseQueryOptions {
	return {
		queryKey: ['getAutopostArticleScheduleTableDataAPI'],
		queryFn: () => {
			let accessToken = getAccessToken();
			if (!accessToken) throw Error("access token missing");

			let axiosConfig: AxiosRequestConfig = {
				method: 'get',
				url: process.env.REACT_APP_DRF_DOMAIN + `/api/frontend/autopost-article-schedule-table-data/`,
				responseType: 'json',
				headers: {
					'Authorization': 'Bearer ' + accessToken
				}
			}
			axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
			return axiosInstance(axiosConfig);
		},
		refetchOnWindowFocus: false,
		retry: (failureCount, error) => {
			const axiosError = error as AxiosError;
			return axiosError.response?.status === 401;
		}
	}
}

// =====================================================================
// ---------------------- REMOVE AUTOPOST ARTICLE ----------------------
// =====================================================================

export const removeAutopostArticleMutation: UseMutationOptions<AxiosResponse<any, any>, any, string> = {
	mutationKey: ['removeAutopostArticleAPI'],
	mutationFn: (articleUID: string) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/autopost-remove-article/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			},
			data: {
				article_uid: articleUID
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		const axiosError = error as AxiosError;
		return axiosError.response?.status === 401;
	}
}

// =====================================================================
// ----------------- REMOVE AUTOPOST SCHEDULED ARTICLE -----------------
// =====================================================================

export const removeAutopostScheduledArticleMutation: UseMutationOptions<AxiosResponse<any, any>, any, string> = {
	mutationKey: ['removeAutopostScheduledArticleAPI'],
	mutationFn: (articleUID: string) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/autopost-schedule-remove-article/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			},
			data: {
				article_uid: articleUID
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		const axiosError = error as AxiosError;
		return axiosError.response?.status === 401;
	}
}

// ================================================================
// ---------------------- RETRY CONTENT PLAN ----------------------
// ================================================================

export const retryContentPlanMutation: UseMutationOptions<AxiosResponse<any, any>, any> = {
	mutationKey: ['retryContentPlanAPI'],
	mutationFn: () => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/retry-content-plan/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		const axiosError = error as AxiosError;
		return axiosError.response?.status === 401;
	}
}

// =======================================================================
// ---------------------- RESEND VERIFICATION EMAIL ----------------------
// =======================================================================

export const resendVerificationEmailMutation: UseMutationOptions<AxiosResponse<any, any>, any> = {
	mutationKey: ['resendVerificationEmailAPI'],
	mutationFn: () => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/resend-verification-email/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		const axiosError = error as AxiosError;
		return axiosError.response?.status === 401;
	}
}

// ==============================================================
// ---------------------- EMAIL PREFERENCE ----------------------
// ==============================================================

export const emailPreferenceMutation: UseMutationOptions<AxiosResponse<any, any>, any, {
	send_notif_email: boolean
}> = {
	mutationKey: ['emailPreferenceAPI'],
	mutationFn: (emailPreference) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/save-email-pref/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			},
			data: {
				send_notif_email: emailPreference.send_notif_email,
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		const axiosError = error as AxiosError;
		return axiosError.response?.status === 401;
	}
}

// =========================================================================
// ---------------------- CONTENT PLAN DONE (polling) ----------------------
// =========================================================================

export function contentPlanDonePollingQuery(): UseQueryOptions {
	return {
		queryKey: ['contentPlanDonePollingAPI'],
		queryFn: () => {
			let accessToken = getAccessToken();
			if (!accessToken) throw Error("access token missing");

			let axiosConfig: AxiosRequestConfig = {
				method: 'get',
				url: process.env.REACT_APP_DRF_DOMAIN + `/api/frontend/content-plan-done/`,
				responseType: 'json',
				headers: {
					'Authorization': 'Bearer ' + accessToken
				}
			}
			axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
			return axiosInstance(axiosConfig);
		},
		refetchOnWindowFocus: false,
		refetchInterval: (data: any) => {
			if (data && data['data']['status'] === "done") {
				return false
			} else {
				return 10000
			}
		},
		refetchIntervalInBackground: true
	}
}


// ===================================================================
// ---------------------- GET CONTENT PLAN DATA ----------------------
// ===================================================================

export function getContentPlanDataQuery(): UseQueryOptions {
	return {
		queryKey: ['getContentPlanDataAPI'],
		queryFn: () => {
			let accessToken = getAccessToken();
			if (!accessToken) throw Error("access token missing");

			let axiosConfig: AxiosRequestConfig = {
				method: 'get',
				url: process.env.REACT_APP_DRF_DOMAIN + `/api/frontend/get-content-plan-data/`,
				responseType: 'json',
				headers: {
					'Authorization': 'Bearer ' + accessToken
				}
			}
			axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
			return axiosInstance(axiosConfig);
		},
		refetchOnWindowFocus: false,
		retry: (failureCount, error) => {
			const axiosError = error as AxiosError;
			return axiosError.response?.status === 401;
		},
		cacheTime: 0
	}
}


// ==============================================================
// ---------------------- INTEGRATION APIS ----------------------
// ==============================================================

export const wordpressIntegrationMutation: UseMutationOptions<AxiosResponse<any, any>, any, string> = {
	mutationKey: ['wordpressIntegrationAPI'],
	mutationFn: (wpSiteURL) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/wp-integration/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			},
			data: {
				wp_site_url: wpSiteURL,
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		const axiosError = error as AxiosError;
		return axiosError.response?.status === 401;
	}
}


// ================================================================
// ---------------------- GOOGLE INTEGRATION ----------------------
// ================================================================

export const googleIntegrationMutation: UseMutationOptions<AxiosResponse<any, any>, any, string> = {
	mutationKey: ['googleIntegrationAPI'],
	mutationFn: (integrationType) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/google-integrations/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			},
			data: {
				integration_type: integrationType
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		const axiosError = error as AxiosError;
		return axiosError.response?.status === 401;
	}
}


// ================================================================
// ---------------------- WEBFLOW INTEGRATION ---------------------
// ================================================================

export const webflowIntegrationMutation: UseMutationOptions<AxiosResponse<any, any>, any, {
	based_on: "app" | "api",
	api_token?: string,
	command?: string,
	collection_id?: string,
	selected_feilds_mapping?: Array<{ code: string, value: string }>
}> = {
	mutationKey: ['webflowIntegrationAPI'],
	mutationFn: (postData: {
		based_on: "app" | "api",
		api_token?: string,
		command?: string,
		collection_id?: string,
		selected_feilds_mapping?: Array<{ code: string, value: string }>
	}) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/webflow-integration/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			},
			data: {
				'based_on': postData.based_on,
				'token': postData.api_token,
				'execute': postData.command,
				'collection_id': postData.collection_id,
				'fields_mapping': postData.selected_feilds_mapping,
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		const axiosError = error as AxiosError;
		return axiosError.response?.status === 401;
	}
}


// ================================================================
// ------------------------ WIX INTEGRATION -----------------------
// ================================================================

export const wixIntegrationMutation: UseMutationOptions<AxiosResponse<any, any>, any, {
	api_token: string,
	site_id: string,
}> = {
	mutationKey: ['wixIntegrationAPI'],
	mutationFn: (postData: {
		api_token: string,
		site_id: string,
	}) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/wix-integration/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			},
			data: {
				'token': postData.api_token,
				'site_id': postData.site_id,
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		const axiosError = error as AxiosError;
		return axiosError.response?.status === 401;
	}
}

/**
 * For saving edited website industry value.
 */
export const saveWebsiteIndustry = (postData: {
	industry: string
}) => {
	return makeAuthPostRequest("/api/frontend/save-website-industry/", postData);
}

/**
 * For saving edited website icp value.
 */
export const saveWebsiteIcp = (postData: {
	icp: string
}) => {
	return makeAuthPostRequest("/api/frontend/save-website-icp/", postData);
}

/**
 * Triggers download for article.
 */
export const downloadArticle = (downloadApiPath: string) => {
	return makeDownloadRequest(
		downloadApiPath
	);
}

/**
 * For fetching data for connect website competitors blacklist section.
 */
export const fetchCompetitorDomainsFromKeywords = (queryData: {
	website_domain: string,
	keywords: Array<string>
}) => {
	return makeGetRequest(
		`/api/frontend/get-competitor-domains-from-keywords?website_domain=${queryData.website_domain}&keywords=${queryData.keywords.join("&keywords=")}`,
	);
}

/**
 * For fetching & saving competitor logo.
 */
export const saveCompLogo = (postData: {
	competitor_domain: string
}) => {
	return makeAuthPostRequest("/api/frontend/save-competitor-logo/", postData);
}

/**
 * For fetching blacklist data for user's active website.
 */
export const getCompetitorDomains = () => {
	return makeAuthGetRequest("/api/frontend/get-competitor-domains/");
}

/**
 * Removes user's website competitors.
 */
export const removeCompetitors = (postData: {
	domains: string[]
}) => {
	return makeAuthPostRequest("/api/frontend/remove-competitors/", postData);
}

/**
 * "Add competitors with given domains to user's website.
 */
export const addCompetitors = (postData: {
	domains: string[]
}) => {
	return makeAuthPostRequest("/api/frontend/add-competitors/", postData);
}

/**
 * Fetches domain data (hypestat data) for given domains.
 */
export const getDomainData = (postData: {
	domains: string[]
}) => {
	return makeAuthPostRequest("/api/frontend/get-domain-data/", postData);
}

// /**
//  * Adds competitor with given domain to user's website.
//  */
// export const addCompetitor = (postData: {
// 	domain: string
// }) => {
// 	return makeAuthPostRequest("/api/frontend/add-competitors/", postData);
// }

/**
 * Fetches data for competitors research page.
 */
export const getCompResearchData = () => {
	return makeAuthGetRequest("/api/frontend/get-competitors-research-data/");
}

export const getConnectWebsiteKeywords = (queryData: {
	domain: string
}) => {
	return makeAuthGetRequest(`/api/frontend/get-connect-website-keywords?domain=${queryData.domain}`);
}

/**
 * Rephrase sentence API.
 */
export const rephraseSentence = (postData: {
	sentence: string
}) => {
	return makeAuthPostRequest('/api/frontend/rephrase-sentence/', postData);
}

/**
 * Generates/Unlocks keywords for provided competitor domain
 */
export const generateCompetitorKeywords = (postData: {
	domain: string,
	selectedLocation?: Object | null,
}) => {
	return makeAuthPostRequest("/api/frontend/generate-competitor-keywords/", postData);
}

/**
 * Fetches keyword data for competitor research.
 * @param queryData
 */
export const getCompetitorResearchKeywordsData = (queryData: {
	competitor_domain: string
}) => {
	return makeAuthGetRequest(
		`/api/frontend/get-competitor-research-keywords-data?competitor_domain=${queryData.competitor_domain}`
	);
}

/**
 * Adds keywords to website's selected list.
 */
export const markKeywordsAsSelected = (postData: {
	keyword_hash_list: string[]
}) => {
	return makeAuthPostRequest("/api/frontend/add-multiple-selected-keyword/", postData);
}

/**
 * Removes given keyword from user's selected keywords list.
 */
export const removeKeywordsFromSelected = (postData: {
	keyword_hash: string
}) => {
	return makeAuthPostRequest("/api/frontend/remove-selected-keyword/", postData);
}

/**
 * Removes given keyword from user's selected keywords list.
 */
export const removeListicleKeyword = (postData: {
	keyword_hash: string
}) => {
	return makeAuthPostRequest("/api/frontend/remove-listicles-keyword/", postData);
}

/**
 * Deletes user's current active website.
 */
export const deleteCurrentWebsite = () => {
	return makeAuthPostRequest("/api/frontend/delete-current-website/");
}


/**
 * Clearbit Domain suggestion API.
 */
export const domainSuggestion = (domainName: string) => {
	return makeAuthGetRequest(`/api/frontend/domain-suggestion/?name=${domainName}`);
}


/**
 * Fetches data for user's active website.
 */
export const getGoogleSuggestions = (keyword: string) => {
	return makeAuthGetRequest("/api/frontend/get-google-suggestions/?keyword=" + keyword);
}


// =================================================================
// ------------- UPLOAD KEYWORDS API TO GET RELATED ----------------
// =================================================================

export const uploadKeywordsToGetRelatedMutation: UseMutationOptions<AxiosResponse<any, any>, any, Array<string>> = {
	mutationKey: ['uploadKeywordsAPI'],
	mutationFn: (keywords: Array<string>) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/get-related-keywords/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			},
			data: {
				keywords: keywords
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		const axiosError = error as AxiosError;
		return axiosError.response?.status === 401;
	}
}


// ============================================================
// --------------- RE-GENERATE ARTICLE TITLE API --------------
// ============================================================

export const regenerateTitleMutation: UseMutationOptions<AxiosResponse<any, any>, any, {
	articleUID: string,
}> = {
	mutationKey: ['regenerateTitleAPI'],
	mutationFn: (postData: { articleUID: string }) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/regenerate-article-title/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			},
			data: {
				article_uid: postData.articleUID,
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		const axiosError = error as AxiosError;
		return axiosError.response?.status === 401;
	}
}


// ============================================================
// ----------- RE-GENERATE ARTICLE CONTENT IMG API ------------
// ============================================================

export const regenerateAIContentImgMutation: UseMutationOptions<AxiosResponse<any, any>, any, {
	articleUID: string,
	imageURL: string
}> = {
	mutationKey: ['regenerateAIContentImgAPI'],
	mutationFn: (postData: { articleUID: string, imageURL: string }) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/regenerate-ai-article-content-img/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			},
			data: {
				article_uid: postData.articleUID,
				image_url: postData.imageURL
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		const axiosError = error as AxiosError;
		return axiosError.response?.status === 401;
	}
}


// ============================================================
// ----------------- google integration fetch data ------------
// ============================================================

export function fetchGSCKeywordsData(): UseQueryOptions {
	return {
		queryKey: ['googleIntegrationFetchDataAPI'],
		queryFn: async () => {
			let accessToken = getAccessToken();
			if (!accessToken) throw Error("access token missing");
			let fetchConfig: RequestInit = {
				method: 'GET',
				headers: {
					'Authorization': 'Bearer ' + accessToken
				}
			};
			const response = await fetch(process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/google-integration-fetch-data/', fetchConfig);
			if (!response.ok) {
				const error = await response.json();
				throw new Error(JSON.stringify(error));
			}
			return response.json();
		},
		refetchOnWindowFocus: false,
		retry: (failureCount, error) => {
			const fetchError = error as Error;
			return fetchError.message === 'Network response was not ok';
		},
		cacheTime: 0
	}
}


// ===================================================================
// ----------------- generate Article Title from Keywords ------------
// ===================================================================
export const generateArticleTitleFromKeyword: UseMutationOptions<AxiosResponse<any, any>, any, {
	keyword: string
	article_type: string
}> = {
	mutationKey: ['generateArticleTitleFromKeyword'],
	mutationFn: (postData: { keyword: string, article_type: string }) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/generate-title-for-keyword/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			},
			data: {
				keyword: postData.keyword,
				article_type: postData.article_type
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		const axiosError = error as AxiosError;
		return axiosError.response?.status === 401;
	}
}


// ==============================================================
// ------------- INDEXATION: SEND PAGES FOR INDEXING ------------
// ==============================================================

export const sendPageForIndexingMutation: UseMutationOptions<AxiosResponse<any, any>, any, Array<string>> = {
	mutationKey: ['sendPageForIndexingAPI'],
	mutationFn: (pages: Array<string>) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/send-pages-for-indexing/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			},
			data: {
				all_pages_urls: pages
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		const axiosError = error as AxiosError;
		return axiosError.response?.status === 401;
	}
}


// ==============================================================
// ----------------- INDEXATION: ALL PAGES API ------------------
// ==============================================================
export function loadAllPageQuery(): UseQueryOptions {
	return {
		queryKey: ['loadAllPageAPI'],
		queryFn: () => loadAllPageQueryFn(),
		refetchOnWindowFocus: false,
		retry: (failureCount, error) => {
			// Error message 'access_token_renewed' is returned by the interceptor code after renewing the access token.
			// In this case return true so that we can attempt the api call again.
			// return error === 'access_token_renewed';
			const axiosError = error as AxiosError;
			return axiosError.response?.status === 401;
		}
	}
}

function loadAllPageQueryFn() {
	let accessToken = getAccessToken();
	if (!accessToken) throw Error("access token missing");

	let axiosConfig: AxiosRequestConfig = {
		method: 'get',
		url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/fetch-all-pages/',
		responseType: 'json',
		headers: {
			'Authorization': 'Bearer ' + accessToken
		}
	}
	axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
	return axiosInstance(axiosConfig);
}


// ==============================================================
// ------------- INDEXATION: POSTED ABUN ARTICLES ---------------
// ==============================================================
export function loadPostedAbunArticlesQuery(): UseQueryOptions {
	return {
		queryKey: ['loadPostedAbunArticlesAPI'],
		queryFn: () => loadPostedAbunArticlesQueryFn(),
		refetchOnWindowFocus: false,
		retry: (failureCount, error) => {
			// Error message 'access_token_renewed' is returned by the interceptor code after renewing the access token.
			// In this case return true so that we can attempt the api call again.
			// return error === 'access_token_renewed';
			const axiosError = error as AxiosError;
			return axiosError.response?.status === 401;
		}
	}
}

function loadPostedAbunArticlesQueryFn() {
	let accessToken = getAccessToken();
	if (!accessToken) throw Error("access token missing");

	let axiosConfig: AxiosRequestConfig = {
		method: 'get',
		url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/fetch-all-posted-abun-articles/',
		responseType: 'json',
		headers: {
			'Authorization': 'Bearer ' + accessToken
		}
	}
	axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
	return axiosInstance(axiosConfig);
}


// ==============================================================
// ----------------- INDEXATION: REPORT STATS -------------------
// ==============================================================
export function loadIndexationReportStatsQuery(): UseQueryOptions {
	return {
		queryKey: ['loadIndexationReportStatsAPI'],
		queryFn: () => loadIndexationReportStatsQueryFn(),
		refetchOnWindowFocus: false,
		retry: (failureCount, error) => {
			// Error message 'access_token_renewed' is returned by the interceptor code after renewing the access token.
			// In this case return true so that we can attempt the api call again.
			// return error === 'access_token_renewed';
			const axiosError = error as AxiosError;
			return axiosError.response?.status === 401;
		}
	}
}

function loadIndexationReportStatsQueryFn() {
	let accessToken = getAccessToken();
	if (!accessToken) throw Error("access token missing");

	let axiosConfig: AxiosRequestConfig = {
		method: 'get',
		url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/indexation-report-stats/',
		responseType: 'json',
		headers: {
			'Authorization': 'Bearer ' + accessToken
		}
	}
	axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
	return axiosInstance(axiosConfig);
}


// ==============================================================
// -------- INDEXATION: ALL PAGES SENT FOR INDEXING API ---------
// ==============================================================
export function loadPagesSentForIndexingQuery(): UseQueryOptions {
	return {
		queryKey: ['loadPagesSentForIndexingAPI'],
		queryFn: () => loadPagesSentForIndexingQueryFn(),
		refetchOnWindowFocus: false,
		retry: (failureCount, error) => {
			// Error message 'access_token_renewed' is returned by the interceptor code after renewing the access token.
			// In this case return true so that we can attempt the api call again.
			// return error === 'access_token_renewed';
			const axiosError = error as AxiosError;
			return axiosError.response?.status === 401;
		}
	}
}

function loadPagesSentForIndexingQueryFn() {
	let accessToken = getAccessToken();
	if (!accessToken) throw Error("access token missing");

	let axiosConfig: AxiosRequestConfig = {
		method: 'get',
		url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/fetch-pages-sent-for-indexing/',
		responseType: 'json',
		headers: {
			'Authorization': 'Bearer ' + accessToken
		}
	}
	axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
	return axiosInstance(axiosConfig);
}


// ==============================================================
// ----------------- New Keyword Research Page -------------------
// ==============================================================
export function getKeywordProjects() {
	return makeAuthGetRequest("/api/frontend/get-keyword-projects/");
}


// =================================================================
// ---------------------- UPLOAD KEYWORDS API ----------------------
// =================================================================

export const uploadKeywordsMutationV2: UseMutationOptions<AxiosResponse<any, any>, any, {
	keywords: Array<string>,
	selectedLocation: Object
	projectName: string,
	keywordsAddedUsing?: "csv" | "input-box"
}> = {
	mutationKey: ['uploadKeywordsAPI'],
	mutationFn: (postData: {
		keywords: Array<string>,
		selectedLocation: Object,
		projectName: string,
		keywordsAddedUsing?: "csv" | "input-box",
	}) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/upload-keywords-v2/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			},
			data: {
				keywords: postData.keywords,
				selectedLocation: postData.selectedLocation,
				project_name: postData.projectName,
				keywords_added_using: postData.keywordsAddedUsing !== undefined ? postData.keywordsAddedUsing : "input-box"
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		// Error message 'access_token_renewed' is returned by the interceptor code after renewing the access token.
		// In this case return true so that we can attempt the api call again.
		// return error === 'access_token_renewed';
		const axiosError = error as AxiosError;
		return axiosError.response?.status === 401;
	}
}



// ==============================================================
// ----------------- Keyword Research Project Data
// ==============================================================
export function getKeywordProjectData(projectId: string) {
	return makeAuthGetRequest("/api/frontend/get-keyword-project-data/?project_id=" + projectId);
}


// =================================================================
// ------------- UPLOAD KEYWORDS API TO GET RELATED ----------------
// =================================================================

export const aiKeywordsResearchMutation: UseMutationOptions<AxiosResponse<any, any>, any, {
	keywords: Array<string>,
	selectedLocation: Object
}> = {
	mutationKey: ['aiKeywordsResearchAPI'],
	mutationFn: (postData: {
		keywords: Array<string>,
		selectedLocation: Object
	}) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/ai-keywords-research-api/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			},
			data: {
				keywords: postData.keywords,
				selectedLocation: postData.selectedLocation
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		const axiosError = error as AxiosError;
		return axiosError.response?.status === 401;
	}
}


// ==============================================================================
// ----------------- google integration fetch connected domains -----------------
// ==============================================================================

export function gscfetchConnectedDomains(): UseQueryOptions {
	return {
		queryKey: ['googleIntegrationFetchConnectedDomainsAPI'],
		queryFn: async () => {
			let accessToken = getAccessToken();
			if (!accessToken) throw Error("access token missing");
			let fetchConfig: RequestInit = {
				method: 'GET',
				headers: {
					'Authorization': 'Bearer ' + accessToken
				}
			};
			const response = await fetch(process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/google-integration-fetch-connected-domains/', fetchConfig);
			if (!response.ok) {
				const error = await response.json();
				throw new Error(JSON.stringify(error));
			}
			return response.json();
		},
		refetchOnWindowFocus: false,
		retry: (failureCount, error) => {
			const fetchError = error as Error;
			return fetchError.message === 'Network response was not ok';
		},
		cacheTime: 0
	}
}


export function fetchGSCKeywordsData2(selectedDomain: String = ""): UseQueryOptions {
	return {
		queryKey: ['googleIntegrationFetchDataAPI'],
		queryFn: async () => {
			let accessToken = getAccessToken();
			if (!accessToken) throw Error("access token missing");
			let fetchConfig: RequestInit = {
				method: 'GET',
				headers: {
					'Authorization': 'Bearer ' + accessToken
				}
			};
			const response = await fetch(process.env.REACT_APP_DRF_DOMAIN + "/api/frontend/google-integration-fetch-data-from-domain/?selected_domain=" + selectedDomain, fetchConfig);
			if (!response.ok) {
				const error = await response.json();
				throw new Error(JSON.stringify(error));
			}
			return response.json();
		},
		refetchOnWindowFocus: false,
		retry: (failureCount, error) => {
			const fetchError = error as Error;
			return fetchError.message === 'Network response was not ok';
		},
		cacheTime: 0
	}
}

/**
 * Removes given keyword from Keyword Research Project.
 */
export const removeKeywordProjectKeywords = (postData: {
	keyword_project_id: string
	keywords: Array<string>
}) => {
	return makeAuthPostRequest("/api/frontend/remove-keyword-project-keywords/", postData);
}


/**
 * Removes given keyword from Keyword Research Project.
 */
export const createCustomTitleForKeywordMutation = (postData: {
	keyword: string
	keywordHash: string
	customArticleTitle: string
}) => {
	return makeAuthPostRequest("/api/frontend/create-custom-title-for-keyword/", postData);
}


// ==============================================================
// ---------------------- FEATURE REQUEST -----------------------
// ==============================================================

export const featureRequestMutation: UseMutationOptions<AxiosResponse<any, any>, any, string> = {
	mutationKey: ['featureRequestAPI'],
	mutationFn: (message) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/feature-request/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			},
			data: {
				message: message,
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		const axiosError = error as AxiosError;
		return axiosError.response?.status === 401;
	}
}


// ===============================================================
// ----------------- New Automation Projects Page ----------------
// ===============================================================
export function getAutomationProjects() {
	return makeAuthGetRequest("/api/frontend/get-automation-projects/");
}


export const saveAutomationProjectMutation: UseMutationOptions<AxiosResponse<any, any>, any, {
	selectedKeywordProjectId: string,
	trafficRange: Array<number>,
	articlesCount: number,
	frequency: string,
	integration: string,
	publishState: string,
	publishDays: Array<string>,
	publishTime: string,
	publishOnlyGeneratedArticles: boolean,
	integrationUniqueID: string
}> = {
	mutationKey: ['saveAutomationProjectAPI'],
	mutationFn: (postData: {
		selectedKeywordProjectId: string,
		trafficRange: Array<number>,
		articlesCount: number,
		frequency: string,
		integration: string,
		publishState: string,
		publishDays: Array<string>,
		publishTime: string,
		publishOnlyGeneratedArticles: boolean,
		integrationUniqueID: string
	}) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/save-automation-project/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			},
			data: {
				selectedKeywordProjectId: postData.selectedKeywordProjectId,
				traffic_range: postData.trafficRange,
				articles_count: postData.articlesCount,
				frequency: postData.frequency,
				integration: postData.integration,
				publish_state: postData.publishState,
				publish_days: postData.publishDays,
				publish_time: postData.publishTime,
				publish_only_generated_articles: postData.publishOnlyGeneratedArticles,
				integration_unique_id: postData.integrationUniqueID
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		const axiosError = error as AxiosError;
		return axiosError.response?.status === 401;
	}
}


export function getAutomationProjectData(projectId: string) {
	return makeAuthGetRequest("/api/frontend/get-automation-project-data/?project_id=" + projectId);
}

export function deleteAutomationProject(projectId: string) {
	return makeAuthPostRequest("/api/frontend/delete-automation-project/", { project_id: projectId });
}


export const updateAutomationProjectMutation: UseMutationOptions<AxiosResponse<any, any>, any, {
	automationProjectId: string,
	trafficRangeMin: number,
	trafficRangeMax: number,
	articlesCount: number,
	frequency: string,
	integration: string,
	publishState: string,
	publishDays: Array<string>,
	publishTime: string,
	publishOnlyGeneratedArticles: boolean,
	integrationUniqueID: string
}> = {
	mutationKey: ['updateAutomationProjectAPI'],
	mutationFn: (postData: {
		automationProjectId: string,
		trafficRangeMin: number,
		trafficRangeMax: number,
		articlesCount: number,
		frequency: string,
		integration: string,
		publishState: string,
		publishDays: Array<string>,
		publishTime: string,
		publishOnlyGeneratedArticles: boolean,
		integrationUniqueID: string
	}) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/update-automation-project/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			},
			data: {
				automation_project_id: postData.automationProjectId,
				trafficRangeMin: postData.trafficRangeMin,
				trafficRangeMax: postData.trafficRangeMax,
				articles_count: postData.articlesCount,
				frequency: postData.frequency,
				integration: postData.integration,
				publish_state: postData.publishState,
				publish_days: postData.publishDays,
				publish_time: postData.publishTime,
				publish_only_generated_articles: postData.publishOnlyGeneratedArticles,
				integration_unique_id: postData.integrationUniqueID
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		const axiosError = error as AxiosError;
		return axiosError.response?.status === 401;
	}
}

// ============================================================
// ----------------- Schedule Article Mutation ----------------
// ============================================================

export const scheduleArticleMutation: UseMutationOptions<AxiosResponse<any, any>, any, any, {
	articleUID: string,
	articleScheduleDate: Dayjs,
	integrationName: string,
	integrationUniqueID: string,
}> = {
	mutationKey: ['scheduleArticleMutationAPI'],
	mutationFn: (postData: { articleUID: string, articleScheduleDate: Dayjs, integrationName: string, integrationUniqueID: string, }) => {
		let accessToken = getAccessToken();
		if (!accessToken) throw Error("access token missing");

		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/schedule-article-auto-publish/',
			responseType: 'json',
			headers: {
				'Authorization': 'Bearer ' + accessToken
			},
			data: {
				article_uid: postData.articleUID,
				schedule_date: postData.articleScheduleDate,
				selected_integration: postData.integrationName,
				selected_integration_id: postData.integrationUniqueID
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	},
	retry: (failureCount: number, error: any) => {
		const axiosError = error as AxiosError;
		return axiosError.response?.status === 401;
	}
}


// ===============================================================
// ----------------- Content Calendar Page ----------------
// ===============================================================
export function getScheduledArticles() {
	return makeAuthGetRequest("/api/frontend/get-scheduled-articles/");
}

// =================================================================================
// ------------ Get All Generated Articles for Automation Project -----------------
// =================================================================================
export function getGeneratedArticlesForAutomationProject(projectId: string) {
	return makeAuthGetRequest("/api/frontend/get-generated-articles-for-automation-project/?keyword_project_id=" + projectId);
}

// ===============================================================
// ------------------------ Get BackLinks ------------------------
// ===============================================================
export function getBackLinks() {
	return makeAuthGetRequest("/api/frontend/get-backlink/");
}

// ============================================================
// -------------------------- Google Auth ---------------------
// ============================================================

export const googleLoginAndSignupAuth: UseMutationOptions<AxiosResponse<any, any>, any, {
	state: string | null,
	code: string | null,
	scope: string | null,
	country: string,
	signup: boolean
}> = {
	mutationKey: ['googleLoginAndSignupAuthAPI'],
	mutationFn: (data: {
		state: string | null,
		code: string | null,
		scope: string | null,
		country: string,
		signup: boolean
	}) => {
		let axiosConfig: AxiosRequestConfig = {
			method: 'post',
			url: process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/google-login-signup/success/',
			responseType: 'json',
			data: {
				state: data.state,
				code: data.code,
				scope: data.scope,
				country: data.country,
				signup: data.signup
			}
		}
		axiosInstance.interceptors.response.use(axiosInterceptorSuccessFn, axiosInterceptorFailFn);
		return axiosInstance(axiosConfig);
	}
}

// Progress polling for task
export function getTaskProgress(taskId: string) {
	return makeAuthGetRequest("/api/frontend/get-task-progress/?task_id=" + taskId);
}


// ================================================================
// ------------------------- WEBFLOW SITES ------------------------
// ================================================================

export function getWebflowSites() {
	return makeAuthGetRequest("/api/frontend/get-webflow-sites");
}
